<template>
  <div class="productCommodity">
    <div>
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :inline="true"
        :rules="rules"
        label-width="110px"
        class="demo-form-inline from_flex"
      >
        <el-form-item label="SKU ID" prop="skuIds" class="from_margin">
          <el-input
            v-model.trim.number="ruleForm.skuIds"
            placeholder="Please Enter"
            style="width: 188px"
            maxlength="10"
            type="text"
            size="mini"
            oninput="value=value.replace(/[^\d\,]/g,'')"
          />
        </el-form-item>
        <!-- Product Brand -->
        <el-form-item
          label="Product brand name"
          prop="brandId"
          class="from_margin"
        >
          <el-select
            v-model.trim="ruleForm.brandId"
            filterable
            clearable
            size="mini"
            placeholder="WIll K"
          >
            <el-option
              v-for="item in options"
              :key="item.brandId"
              :label="item.brandName"
              :value="item.brandId"
            />
          </el-select>
        </el-form-item>

        <!-- roduct Name -->
        <el-form-item label="SKU Name" prop="skuName" class="from_margin">
          <el-input
            v-model.trim="ruleForm.skuName"
            size="mini"
            placeholder="Please Enter"
          />
        </el-form-item>

        <!-- Categories -->
        <el-form-item
          label="Product category"
          prop="goriesValue"
          class="from_margin lable"
        >
          <template>
            <!-- <el-cascader
              ref="cascader"
              v-model.trim="valueCascader"
              change="cascaderChange"
              placeholder="Please Enter"
              clearable
              size="mini"
              :options="optionsCategories"
              :show-all-levels="false"
            /> -->
            <NewCascader @change="getAllSelectLists" />
          </template>
        </el-form-item>
        <!-- 查询按钮 -->
        <div
          class="query from_margin"
          style="margin-top: 24px; margin-left: 50px"
        >
          <el-button size="mini" @click="rest"> Rest </el-button>
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="onSubmit"
          >
            Search
          </el-button>
        </div>

        <!-- 单选框 -->
        <el-form-item
          v-show="flang"
          style="display: block; margin-left: 50px"
          label="SKU Type"
          prop="venderType"
          class="from_margin"
          label-width="80px"
        >
          <template>
            <el-radio-group v-model.trim="ruleForm.venderType" size="small">
              <el-radio label="0">POP goods</el-radio>
              <el-radio label="1">Self-employed goods</el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
      </el-form>

      <template>
        <Dialog
          v-show="flang"
          style="margin: 10px 0 0 50px"
          :dates="tableData"
          @Export="Exportrs"
          @Refresh="Refreshs"
        />
        <!-------------------------------------------------表单------------------------- -->
        <template>
          <div style="padding: 0 16px">
            <el-table
              :data="tableData"
              style="width: 100%"
              :header-cell-style="{ background: '#e8f1fe' }"
              @selection-change="handleSelectionChange"
            >
              <!-- 修改无数据时 提示文字 -->
              <template slot="empty" class="logo_no">
                <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
                <p style="color: #969799">No more data</p>
              </template>
              <el-table-column type="selection" width="55" />
              <el-table-column prop="skuId" label="SKU ID" min-width="135" />
              <el-table-column
                prop="skuName"
                label="SKU Name"
                min-width="260"
                :show-overflow-tooltip="true"
              />
              <el-table-column
                prop="thrCateName"
                label="Product Category Name"
                min-width="210"
              />
              <el-table-column
                prop="mallPrice"
                label="Mall Price"
                min-width="120"
              />
              prop="purchaser" label="Purchaser" width="140" />
              <el-table-column prop="state" label="SKU Status" min-width="180">
                <template slot-scope="scope">
                  <i
                    :ref="scope.$index"
                    :class="
                      scope.row.state == 0 ? 'el-icon-success' : 'el-icon-error'
                    "
                    :style="
                      scope.row.state == 0 ? 'color:#40C740' : 'color:#FA5050'
                    "
                  />
                  <span
                    v-show="scope.row.state == 0 ? true : false"
                    :ref="scope.$index"
                    class="succeedSpan"
                  >
                    Put on the shelf
                  </span>
                  <span
                    v-show="scope.row.state == 1 ? true : false"
                    :ref="scope.$index"
                    class="failureSpan"
                  >
                    Off the shelf
                  </span>
                </template>
              </el-table-column>
              <el-table-column prop="venderTypeName" label="SKU Type">
                <template slot-scope="scope">
                  <span>{{ scope.row.venderTypeName || '--' }}</span>
                </template>
              </el-table-column>
              <!-- Look at the contract号 -->
              <el-table-column
                v-if="flang"
                fixed="right"
                label="Operation"
                min-width="180"
              >
                <template slot-scope="scope">
                  <el-link
                    :ref="scope.$index"
                    type="primary"
                    :underline="false"
                    style="
                    font-size: 14px;
                    font-family: ArialMT;
                    text-decoration: none;
                  "
                    @click="getViews(scope.$index, scope)"
                  >
                    View Contract No
                  </el-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
        <!------------------------------- 分页 ------------------------------------------------->
        <div class="pages">
          <el-pagination
            :current-page="page.currentPage"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="page.size"
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.totals"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </template>
      <div style="height: 50px" />
    </div>

    <!-- table-view-Dialog-->
    <el-dialog
      title="The contract information"
      :visible.sync="dialogVisibleView"
    >
      {{ contract }}
    </el-dialog>
    <!-- table-Dialog -->
    <el-dialog
      title="Are you sure you want to delete the data?"
      :visible.sync="dialogVisibleDelete"
      width="50%"
      :before-close="handleClose"
    >
      <span
        style="background: rgb(247, 184, 129); font-size: 15px; padding: 2px"
      >
        warning,the operation has risk,please be careful
      </span>
      <p>
        if you delete the sku, the same sku in pool will be delete too,maybe the
        coustomer who hans already add this sku can not buy it
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleDelete = false">confirm</el-button>
        <el-button type="primary" @click="dialogVisibleDelete = false">cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Dialog from './components/dialog.vue';
import NewCascader from '../../../components/newCascader';
import { mapMutations } from 'vuex';
import {
  getProductList,
  Export,
  getView,
  getAllBrand
} from '@/api/VspProductApi/api';

export default {
  components: {
    Dialog,
    NewCascader
  },
  props: {
    flang: {
      type: Boolean
    }
  },
  data() {
    return {
      http: process.env.VUE_APP_BASE_API + '/system/ware/file/uploadTemplate',

      emptyImgSrc: '',
      // tabs默认选中
      activeName: 'first',
      // 表单值
      ruleForm: {
        skuIds: null,
        brandId: null, //Product Brand的值
        skuName: null,
        venderType: '-1'
      },
      valueCascader: '',
      rules: {}, //验证方法
      options: [], //级联选择器数据
      optionsCategories: [
        {
          value: 'id',
          level: 'level',
          label: 'name',
          children: 'childList' //这里最好要后台返回树形数据
        }
      ],
      // table 表格数据
      tableData: [],
      // 弹窗合同信息
      gridData: [],
      input: '', //popover的值
      visible: false,
      // 分页数据
      page: {
        totals: 0, //总数据
        currentPage: 1, //页数
        size: 10 //每页显示多少条数据
      },
      // 控制Viewdialog的
      dialogVisibleView: false,
      // 控制Deletedialog的
      dialogVisibleDelete: false,
      multipleSelection: [],
      contract: null,
      //选中的信息
      checkId: ''
    };
  },
  computed: {},
  watch: {
    page(newVal) {
      this.$store.commit('tableDatas', newVal);
    }
  },
  created() {
    this.emptyImgSrc = '';
  },
  mounted() {
    this.getProductLists();
    this.getAllBrands();
  },
  methods: {
    ...mapMutations(['addList']),
    // 删除后调用
    Refreshs() {
      this.getProductLists();
    },
    //取消全选
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    onSubmit() {
      this.getProductLists();
    },
    // 重置
    rest() {
      this.$refs.ruleForm.resetFields();
      this.page.currentPage=1;
      this.getProductLists();
      this.valueCascader = null;
    },
    getAllSelectLists(data) {
      this.valueCascader=data[data.length-1]
    },
    // 多选框获得选中的信息
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.$emit('handleSelectionChanges', this.multipleSelection);
      this.checkId = val.map(item => {
        return item;
      });
      this.addList(this.checkId);
    },
    // 修改价格信息
    deleteHandlers(index, row) {},
    handleSizeChange(val) {
      this.page.size = val;
      this.getProductLists();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.getProductLists();
    },
    handleDelete(index, row) {
      this.dialogVisibleDelete = true;
    },
    handleClose(done) {
      this.$confirm('Confirm the shut down')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    // 修改价格
    deleteHandler(index, row) {},
    handleClick(tab, event) {},

    // 下面都是接口
    // 搜索条件里面的品牌接口
    getAllBrands() {
      getAllBrand().then(res => {
        this.options = res.data;
      });
    },
    // 列表查询
    getProductLists() {
      var thrCateId=null
      if (this.valueCascader != null) {
        thrCateId = this.valueCascader[this.valueCascader.length - 1];
      }
      getProductList(
        {
          skuInfoDTO: Object.assign(
            {
              thrCateId: thrCateId
            },
            this.ruleForm
          ),
          pageSize: this.page.size,
          pageNo: this.page.currentPage,
        }
      ).then(res => {
        if (res.success && res.code === 200) {
          this.tableData = res.data.dataList;
          this.page.totals = res.data.totalCount;
        } else {
          this.tableData = [];
          this.page.totals = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 查询合同接口
    getViews(index, row) {
      this.dialogVisibleView = true;
      getView(row.row.skuId).then(res => {
        // console.log(res.data[0].contractNo);
        this.contract = res.data[0].contractNo;
      });
    },
    // 导出当前数据
    Exportrs() {
      Export(this.ruleForm).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = 'data.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    cascaderChange(val) {
      let nodesObj = this.$refs['cascader'].getCheckedNodes();
    }
  }
};
</script>
<style lang="less" scoped>
//@import url(); 引入公共css类
.productCommodity {
  background-color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  .from_margin {
    margin: 20px 0 0 15px;
  }
  .from_margin:nth-of-type(2) {
    /deep/.el-form-item__label {
      line-height: 20px !important;
    }
  }
  .from_margin:nth-of-type(4) {
    /deep/.el-form-item__label {
      line-height: 20px !important;
    }
  }

  ::v-deep .el-form-item__content {
    .el-input__inner {
      height: 32px;
    }
  }
  .query {
    margin: 50px 10px 20px 20px;
    display: inline-block;
    .el-button {
      width: 100px !important;
      height: 32px !important;
      margin-right: 20px;
      ::v-deep .el-icon-search {
        font-size: 14px;
      }
    }
  }
  .dialog-footer > .el-button {
    padding: 0 !important;
  }
  /deep/ .el-tabs__item {
    width: 160px !important;
    text-align: center;
  }
  .isFixed {
    position: fixed;
    top: 0;
  }
  .succeedSpan {
    width: 76px;
    height: 22px;
    font-size: 14px;
    font-family: ArialMT;
    text-align: center;
  }
  .failureSpan {
    background: #ff721f;
    width: 96px;
    height: 25px;
    border-radius: 2px;
    color: #ffffff;
    text-align: center;
  }

  /deep/.el-button {
    // padding: 0 12px !important;
    //width: 100px;
    font-family: ArialMT;
    font-size: 14px;
  }

  ::v-deep .el-button--primary {
    color: #fff;
    background-color: #2262ff;
    border-color: #2262ff;
  }
  ::v-deep .el-button--text {
    color: #2262ff;
  }

  ::v-deep .el-link--primary {
    color: #2262ff;
  }

  ::v-deep .el-radio-group {
    .el-radio__label {
      color: #242526;
    }
    .is-checked {
      .el-radio__inner {
        background-color: #2262ff;
      }
      .el-radio__label {
        color: #242526;
      }
    }
  }

  ::v-deep .el-table {
    .cell {
      white-space: pre-wrap;
      word-break: keep-all;
    }
  }

  ::v-deep .el-pager {
    li {
      border: 0;
    }
  }
  .pages {
    margin-top: 10px;
    margin-right: 16px;
    float: right;
    height: 50px;
  }
}
</style>
