<template>
  <div style="width:100%">
    <el-cascader
      ref="tree"
      v-model="value"
      :options="optionsData"
      :props="propsInfo"
      clearable
      collapse-tags
      @change="changeLabel"
    />
  </div>
</template>

<script>
import { getCategoryAndParentVOList } from '@/api/user/list';
export default {
  name:'CheckCascader',
  data() {
    return {
      value: [],
      shareScopeEnd: [],
      optionsData:[],
      propsInfo:{
        value: 'id',
        label: 'name',
        children:'childList',
        multiple: true, 
        // checkStrictly: true
      },
      level1: false,
      level2: false,
    }
  },
  mounted(){
    getCategoryAndParentVOList().then((res) => {
      this.optionsData = res.data
    });
  },
  methods: {
    changeLabel(val) {
      // 是否与上次的类型相同
      let changeFlag = false
      let changeItem = null
      this.level1 = false
      this.level2 = false
      if (this.shareScopeEnd.length == 0) {
        this.value = val
      } else {
        // 与原数组比对
        // this.value.forEach((item) => {
        //   if (item[0] !== this.shareScopeEnd[0][0]) { // 一级标签不同
        //     changeFlag = true
        //     changeItem = item
        //   } 
        //   else if ((!item[1] && this.shareScopeEnd[0][1]) || (item[1] && !this.shareScopeEnd[0][1])) { 
        //     changeFlag = true
        //     changeItem = item
        //   } 
        //   else if ((!item[2] && this.shareScopeEnd[0][2]) || (item[2] && !this.shareScopeEnd[0][2])) {
        //     changeFlag = true
        //     changeItem = item
        //   }
        // })
        let arrValue1 = []
        let arrValue2 = []
        val.forEach((item,index) => {
          if (item[0] !== this.shareScopeEnd[0][0]) { // 一级标签不同
            arrValue1.push(item)
            this.level1 = true
          } else {
            this.level2 = true;
            arrValue2.push(item)
          }
        })
        if (this.level1) {
          this.value = arrValue1;
        } else {
          this.value = arrValue2;
        }

      }
      // if (changeFlag) {
      //   this.value = []
      //   this.value.push(changeItem)
      // }
      
      this.shareScopeEnd = this.value
      const lastDate=[]
      
      this.value.forEach(item=>{
        lastDate.push(item[2])
        // console.log('----item',item[2])
      })
      this.$emit('change',lastDate)
    }
  }
}
</script>

<style lang="less" scoped>
.el-cascader{
    width: 100%;
}
</style>