<template>
  <div>
    <el-upload
      ref="fileList"
      class="upload-demo"
      :action="thisApi + '/system/ware/file/uploadTemplate'"
      :data="{ bucket: 'xx-vsp-wares' }"
      :on-success="successChangesss"
      style=""
      :show-file-list="false"
    >
      <el-button size="" type="" style="height:40px">
        Fill Contract Form Excel
      </el-button>
    </el-upload>
    <div class="add_box form1">
      <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="Name" prop="poolName">
          <el-input
            v-model.trim="ruleForm.poolName"
            type=""
            placeholder="Please separate the Name"
            maxlength="50"
          />
        </el-form-item>
        <el-form-item label="Contract No" prop="contractNos">
          <el-input
            v-model.trim="ruleForm.contractNos"
            type="textarea"
            :rows="5"
            placeholder="Please separate muitiple contractlds with commas."
          />
        </el-form-item>
        <el-form-item label="" prop="">
          <div style="display: flex;">
            <el-upload
              ref="fileList"
              class="upload-demo"
              :action="thisApi + '/system/pool/excelImport'"
              :data="{ contractNo: 'xx_jfdjbksbidfy' }"
              :on-success="successChange"
              style=""
              :show-file-list="false"
            >
              <el-button size="" type="" style="height:40px">
                Fill Contract Form Excel
              </el-button>
            </el-upload>
            <el-button
              size="small"
              type="text"
              icon="sl-icon-download"
              style="float:left;margin-left:10px;height:40px"
              @click="downLoadcontractNo"
            >
              Export Excel Template
            </el-button>
            <el-button
              size="small"
              style="float:left;margin-left:10px;height:40px"
              @click="showCOntract"
            >
              Select Contract No
            </el-button>
          </div>
        </el-form-item>
        <el-form-item label="Product Pool SKU" prop="skuIds">
          <el-tabs v-model.trim="activeName" type="card" style="width:100%">
            <el-tab-pane label="Edit the product pool sku" name="first">
              <el-form-item label="" prop="">
                <el-input
                  v-model.trim="ruleForm.skuIds"
                  type="textarea"
                  :rows="5"
                  placeholder="SKU is separated by ',' and can be emtered at most 30000 You can fill this by 'Fill SKU Form Excel' button below"
                  style="width:100%"
                />
              </el-form-item>
              <el-form-item label="" prop="" style="margin-top: 22px;">
                <div style="display: flex;">
                  <!-- <el-button
                    size="small"
                    type=""
                    icon="sl-icon-download"
                    style="float:left"
                  >
                    Fill SKU Form Excel
                  </el-button> -->
                  <el-upload
                    ref="fileList"
                    class="upload-demo"
                    :action="thisApi + '/system/pool/excelImport'"
                    :data="{ contractNo: 'xx_jfdjbksbidfy' }"
                    :on-success="successChange1"
                    style=""
                    :show-file-list="false"
                  >
                    <el-button size="" type="" style="height:40px">
                      Fill SKU Form Excel
                    </el-button>
                  </el-upload>
                  <el-button
                    size="small"
                    type="text"
                    icon="sl-icon-download"
                    style="float:left;margin-left:10px;height:40px"
                    @click="downLoadsku"
                  >
                    Export Excel Template
                  </el-button>
                  <el-button
                    size="small"
                    style="float:left;margin-left:10px;height:40px"
                    @click="showSku"
                  >
                    Select SKU
                  </el-button>
                </div>
              </el-form-item>
            </el-tab-pane>
            <el-tab-pane
              label="Use three levels of classification"
              name="second"
            >
              <!-- <Category @change="getAllSelectLists" /> -->
              <CheckCascader @change="getSelectList" />
            </el-tab-pane>
          </el-tabs>
        </el-form-item>
        <el-form-item label="Remark" prop="remark">
          <el-input
            v-model.trim="ruleForm.remark"
            type="textarea"
            :rows="5"
            placeholder="Please separate the product pool notes"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="createPoolProdout">
          Create Prodout PoolProdout Pool
        </el-button>
      </div>
    </div>
    <!-- 合同弹窗 -->
    <el-dialog
      title="Choice Contract No"
      :visible.sync="dialogFormVisible"
      width="50%"
    >
      <div class="serch_box">
        <el-form
          :inline="true"
          class="user-search fixed_form"
          size="mini"
          label-width="190"
        >
          <el-form-item label="Contract No:" size="mini">
            <template>
              <el-input
                v-model.trim="form.contractNo"
                placeholder="please enter Contract No"
                size="mini"
                maxlength="100"
              />
            </template>
          </el-form-item>
          <!-- <el-form-item label="Enterprise Name:" size="mini">
            <template>
              <el-input
                v-model.trim="form.enterpriseName"
                size="mini"
                placeholder="please enter Enterprise Name"
                maxlength="100"
              />
            </template>
          </el-form-item> -->
        </el-form>
        <div style="margin: 20px">
          <el-button type="" size="mini" @click="offBtn">Reset</el-button>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="searchBtn"
          >
            Search
          </el-button>
        </div>
      </div>
      <div class="panel-body">
        <el-table
          :data="tableList"
          fit
          style="width: 100%"
          :header-cell-style="{ background: '#e8f1fe' }"
          tooltip-effect="dark"
          @selection-change="handleSelectionChange"
        >
          <!-- 修改无数据时 提示文字 -->
          <template slot="empty" class="logo_no">
            <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
            <p style="color: #969799">No more data</p>
          </template>
          <el-table-column type="selection" width="55" align="center" />
          <el-table-column
            label="Contract No"
            min-width="220"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span class="font_blue" @click="conBtn(scope.row)">
                {{ scope.row.contractNo }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="Enterprise Name"
            min-width="160"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.enterpriseName || '--' }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div class="pageWrapper">
          <el-pagination
            :current-page="listQuery.page"
            :page-sizes="[10, 20, 300, 400]"
            :page-size="listQuery.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="listQuery.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="updateData('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
    <!-- SKU 弹窗 -->
    <el-dialog title="Choice SKU" :visible.sync="dialogSKU" width="50%">
      <Delotal
        :flang="flang"
        @handleSelectionChanges="handleSelectionChanges"
      />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Category from '../../components/categoryCascader/categoryCascader/CategorySelect.vue';
import Delotal from '../VspProduct/commodity/commo.vue';
import { addSKU, addxinhui, downTemplat } from '@/api/user/list';
import { download } from '@/api/VspProductApi/api';
import { getContractList, userBind, changeswitch } from '@/api/user/list';
import CheckCascader from '../../components/checkCascader'
const defaultForm = {
  remark: '',
  poolName: '',
  contractNos: '',
  skuIds: '',
  cateId:''
};
const defaultForms = {
  keyword: '',
  contractNos: '',
  imgUrl: '',
  state: 0,
  url: '',
  positionIndex: null,
  fileList: []
};
export default {
  name: 'Addcommodity',
  // 引入局部刷新
  inject: ['reload'],
  components: {
    // Category,
    Delotal,
    CheckCascader
  },
  data() {
    return {
      ruleForm: Object.assign({}, defaultForm),
      postForm: Object.assign({}, defaultForms),
      emptyImgSrc: '',
      dialogStatus: 'create',
      activeName: 'first',
      invertLists: [],
      allSelectLists: [],
      textMap: {
        update: 'Update Banner',
        create: 'Add Banner'
      },
      // 搜索条件
      form: {
        contractNo: '',
        // enterpriseName: '',
        contractType: null,
        saler: '',
        businessAuditState: '',
        creditAuditState: '',
        state: null
      },
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      dialogStatuss: 'update',
      lastSelectLists: [],
      dialogFormVisible: false,
      thisApi: process.env.VUE_APP_BASE_API,
      // 字典表
      contractTypeList: [{ value: '0', label: 'VSP' }],
      excel: 'conTemplate.xlsx',
      excelSKU: 'skuTemplat.xlsx',
      tableList: [],
      rules: {
        poolName: [
          {
            required: true,
            message: 'Enter the pool Name',
            trigger: 'change'
          }
        ],
        contractNos: [
          {
            required: true,
            message: 'Enter the contractNo',
            trigger: 'blur'
          }
        ]
      },
      Loadshow: false,
      multipleSelection: [],
      multipleSelections: [],
      dialogSKU: false,
      flang: false
    };
  },
  created() {
    //console.log(123);
    this.emptyImgSrc = '';
  },
  methods: {
    showSku() {
      this.dialogSKU = true;
    },
    handleSelectionChanges(val) {
      console.log(val);
      this.multipleSelections = val;
    },
    updateData() {
      if (this.multipleSelection.length > 0) {
        var list = [];
        for (let i = 0; i < this.multipleSelection.length; i++) {
          const element = this.multipleSelection[i];
          list.push(element.contractNo);
        }
        this.ruleForm.contractNos = list.toString();
        this.dialogFormVisible = false;
      }
    },
    updateDatas() {
      if (this.multipleSelections.length > 0) {
        var list = [];
        for (let i = 0; i < this.multipleSelections.length; i++) {
          const element = this.multipleSelections[i];
          list.push(element.skuId);
        }
        this.ruleForm.skuIds = list.toString();
        this.dialogSKU = false;
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    offBtn() {
      this.form.contractNo=''
      this.listQuery.pageCode = 1;
      this.getFirstData()

      // this.reload();
    },
    loadshowBtn() {
      this.Loadshow = !this.Loadshow;
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = '1';
      this.getFirstData();
    },
    // 打开合同列表
    showCOntract() {
      this.getFirstData();
      this.dialogStatuss = 'create';
      this.dialogFormVisible = true;
    },
    // 获取页面数据
    getFirstData() {
      // this.total = 1;
      getContractList(
        Object.assign(
          {
            pageNo: this.listQuery.pageCode,
            pageSize: this.listQuery.pageSize
          },
          this.form
        )
      ).then(response => {
        this.tableList = response.data.dataList;
        this.listQuery.total = response.data.totalCount;
      });
    },
    // 新增
    createPoolProdout() {
      if (this.lastSelectLists.length > 0) {
        // let list= []
        // this.lastSelectLists.forEach(item=>{
        //   list.push(item.id)
        // })
        // console.log(list);
        // this.ruleForm.cateId = String(list);
        this.ruleForm.cateId = String(this.lastSelectLists);
      }
      
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          addSKU(this.ruleForm)
            .then(res => {
              if (res.code == 200) {
                this.$message({
                  message: 'Description Creating a commodity pool succeeded',
                  type: 'success'
                });
                this.$router.push('/productPool/Pool')
              } else {
                this.$message({
                  message: res.msg,
                  type: 'error'
                });
                
              }
            })
            .catch(() => {
              this.$message({
                message: 'Description Failed to create a commodity pool',
                type: 'error'
              });
            });
        } else {
          //console.log('error submit!!');
          return false;
        }
      });
    },
    // 获取选中项
    getAllSelectLists(data) {
      this.lastSelectLists=data[data.length-1]
    },
    getSelectList(data){
      this.lastSelectLists=data
    },
    // 删除contract
    relayContract() {
      this.ruleForm.contractNos = '';
    },
    // 删除sku
    relaySKU() {
      this.ruleForm.skuIds = '';
      this.invertLists = [];
      this.allSelectLists = [];
      this.lastSelectLists = [];
    },
    // 下载contractNo模板
    downLoadcontractNo() {
      let excel = this.excel;
      download(excel).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = this.excel;
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    isExcel(type, name, data) {
      const link = document.createElement('a');
      const blob = new Blob([data]);
      link.style.display = 'none';
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `${name}.` + type);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 下载sku模板
    downLoadsku() {
      // window.location.href =
      //   this.thisApi + '/system/user/downloadTarget?fileName=SKUTemplat.xls';
      let excel = this.excelSKU;
      download(excel).then(res => {
        var downloadUrl = window.URL.createObjectURL(res);
        var anchor = document.createElement('a');
        anchor.href = downloadUrl;
        anchor.download = this.excelSKU;
        anchor.click();
        window.URL.revokeObjectURL(res);
      });
    },
    successChange(file, fileList) {
      if (file.code == 200) {
        // this.ruleForm.contractNo =
        var list = [];
        for (let i = 0; i < file.data.length; i++) {
          const element = file.data[i];
          //console.log(element);
          list.push(element.contractNo);
        }
        this.ruleForm.contractNos = list.toString();
      } else {
        this.$message({
          message: file.msg,
          type: 'error'
        });
      }
    },
    successChangesss(file, fileList) {
      console.log(file);
    },
    successChange1(file, fileList) {
      if (file.code == 200) {
        var list = [];
        for (let i = 0; i < file.data.length; i++) {
          const element = file.data[i];
          //console.log(element);
          list.push(element.skuId);
        }
        this.ruleForm.skuIds = list.toString();
      } else {
        this.$message({
          message: file.msg,
          type: 'error'
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.add_box {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dialog-footer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.btn_box {
  display: flex;
  margin-left: 36px;
}
.upload-demo > div {
  width: 203px;
  height: 32px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}
.fill_btn {
  width: 132px;
  height: 32px;
  background: rgba(34, 98, 255, 1);
  border-radius: 4px;
  padding: 0;
  margin-left: 16px;
}
/deep/.el-form-item__content {
  display: flex;
}
/deep/.el-button {
  // padding: 0 12px !important;
}
::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
}
</style>
