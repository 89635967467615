<template>
  <div style="width:100%">
    <el-cascader
      v-model="value"
      :options="optionsData"  
      placeholder="Please Choose"
      :props="optionProps"
      clearable
      @change="changeOptions"
    />
  </div>
</template>

<script>
import { getCategoryAndParentVOList } from '@/api/user/list';
export default {
  name: 'NewCascader',
  data() {
    return {
      value: [],
      optionProps:{
        value: 'id',
        label: 'name',
        children:'childList',
        multiple:true,
        checkStrictly:true
      },
      optionsData: [],  
    };
  },

  mounted() {
    getCategoryAndParentVOList().then((res) => {
      this.optionsData=res.data
    });
  },

  methods: {
    changeOptions(value) {
      this.$emit('change',value);
    },
        
  },
};
</script>

<style lang="less" scoped>
.el-cascader{
    width: 100%;
}
</style>